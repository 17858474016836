import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './unsaved-changes-modal.component.html',
    styleUrls: ['./unsaved-changes-modal.component.scss']
})
export class UnsavedChangesModalComponent {

    title: string;
    text: string;
    subject: Subject<boolean>;

    constructor(private dialogRef: MatDialogRef<UnsavedChangesModalComponent>) {
    }

    action(value) {
        this.dialogRef.close();
        this.subject.next(value);
        this.subject.complete();
    }

}

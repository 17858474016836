<finstein-base-modal [disableClose]="true" title="core.unsaved-changes.title" bodyClass="none">
    <finstein-circle-info header></finstein-circle-info>
    <finstein-plain-text-content content text="core.unsaved-changes.text"></finstein-plain-text-content>
    <finstein-action-confirm actions
        type="confirm"
        (yesClick)="action(true)"
        (noClick)="action(false)">
    </finstein-action-confirm>
</finstein-base-modal>

